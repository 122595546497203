import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import LoadingScreen from './components/LoadingScreen/LoadingScreen'
import Dashboard from './components/Dashboard/Dashboard'
import { doAuth } from './services/authenticator'

import { authLoading, authSuccess, authFailed, vehicleListSuccess, vehicleListLoading, vehicleListFailed } from './actions/actionTypes'
import { Redirect } from 'react-router'

import axios from 'axios'
import config from './config.json'

//import dummyData from './data/data.json'

import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";
import Login from './components/Login/Login'

function PrivateRoute({ children, authenticated, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          authenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
}


function initAppState(dispatch) {
  return async function (user) {
    dispatch(vehicleListLoading())
    try {
      const response = await axios.get(config.dataUrl)
      dispatch(vehicleListSuccess(response.data.Data))
      dispatch(authSuccess(user))

      // setTimeout(()=>{
      //   dispatch(vehicleListSuccess(dummyData.Data))
      //   dispatch(authSuccess(user))
      // }, 50)

    } catch (err) {
      console.log(err)
      dispatch(authSuccess(user))
      dispatch(vehicleListFailed(err))
    }
  }
}


const ApplicationContainer = ({ loading, authenticated, dispatch }) => {

    useEffect(() => {
        dispatch(authLoading())
        doAuth(initAppState(dispatch), () => { dispatch(authFailed()) })
    }, [dispatch])

    if (loading) return <LoadingScreen />

    return (
        <Router>
            <Switch>
                <Route path="/login">
                    <Login />
                </Route>
                <PrivateRoute path="/" authenticated={authenticated}>
                    <Dashboard />
                </PrivateRoute>
            </Switch>
        </Router>
    )
}

const mapStateToProps = (state) => ({
    loading: state.auth.authLoading,
    authenticated: state.auth.authenticated,
})

export default connect(mapStateToProps)(ApplicationContainer)