import React, { useEffect, useRef, useState } from 'react'
import { Container, Col, Row, Spinner, Alert, Button } from 'reactstrap'
import axios from 'axios'

import SettingEditor from './SettingEditor'
import config from '../../config.json'

import Setting from './Setting'

function findAlarmSettingByDesc(alarmSettings, desc) {
    for (let i = 0; i < alarmSettings.length; i++) {
        if (alarmSettings[i].AlarmDesc === desc) {
            return alarmSettings[i]
        }
    }
    return null
}

function createSettingsFromServer(alarmSettings, availableSettings) {

    // return Date.parse("2011-10-10T14:48:00.000+09:00")
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)

    return availableSettings.map((alarmDesc) => {

        let existing = findAlarmSettingByDesc(alarmSettings, alarmDesc)

        let name = alarmDesc
        let status = "ON"
        let date = tomorrow.toISOString()
        let comment = ""

        if (existing) {
            let ds = existing.DeliverySpec
            if (ds === "OFF") {
                status = "OFF"
            } else {
                if (ds.indexOf(",") > -1) {
                    let elements = ds.split(",")
                    status = elements[0]
                    date = elements[1]
                } else {
                    status = "SNOOZED"
                    date = existing.DeliverySpec
                }
            }
            comment = existing.Comment
        }

        return Setting(name, status, date, comment)
    })
}


const AlarmSettings = ({ vehicle }) => {

    const [busy, setBusy] = useState(false)
    const [saving, setSaving] = useState("")
    const [error, setError] = useState("")
    const [settings, setSettings] = useState([])
    const originalData = useRef()

    const updateSetting = (index, setting) => {
        let settingsCopy = JSON.parse(JSON.stringify(settings))
        settingsCopy[index] = setting
        setSettings(settingsCopy)
    }

    const reset = () => {
        if (originalData.current) {
            setSettings(originalData.current)
        }
    }

    const save = (vehicle) => {
        let alarmSettings = []

        for (let i = 0; i < settings.length; i++) {
            const s = settings[i]

            let alarmSetting = {
                AlarmDesc: s.name,
                DeliverySpec: "",
                Comment: ""
            }

            if (s.status === "OFF") {
                alarmSetting.DeliverySpec = "OFF"
                alarmSettings.push(alarmSetting)
            }

            if (s.status === "SNOOZED" || s.status === "ON-UNTIL") {
                alarmSetting.DeliverySpec = `${s.status},${s.date}`
                alarmSetting.Comment = s.comment
                alarmSettings.push(alarmSetting)
            }
        }

        const now = new Date()
        let request = {
            TrackerID: vehicle.TrackerID,
            UpdateDate: now.toISOString(),
            AlarmSettings: alarmSettings
        }

        setSaving("Saving alarm settings..")

        axios.post(`${config.apiserverUrl}/setalarmsettings`, request, { auth: "55f52172-b295-11eb-8529-0242ac130003" })
            .then(resp => {
                // console.log(request)
                setSaving("Saved successfully.")
            })
            .catch(err => {
                setError(`${err}`)
                console.log("ERROR>> ", err)
            })
            .then(() => {
                setTimeout(() => {
                    setSaving("")
                }, 2000)
            })
    }

    // type AlarmSetting struct {
    //     AlarmDesc    string
    //     DeliverySpec string
    //     Comment      string  <<<<<<<<<<< NEW!
    // }

    // type GetAlarmSettingsResponse struct {
    //     Success           bool
    //     Errors            []string
    //     TrackerID         string
    //     AvailableSettings []string
    //     AlarmSettings     []AlarmSetting
    // }

    // type SetAlarmSettingsRequest struct {
    //     TrackerID     string
    //     UpdateDate 	  string
    //     AlarmSettings []AlarmSetting
    // }

    useEffect(() => {
        setSettings([])
        setError("")
        setBusy(true)

        axios.get(`${config.apiserverUrl}/getalarmsettings?trackerid=${vehicle.TrackerID}`, { auth: "55f52172-b295-11eb-8529-0242ac130003" })
            .then(resp => {
                // console.log(resp)
                setBusy(false)

                const data = resp.data
                // {
                //     "UpdateDate": "2022-07-26T12:26:09.006Z",
                //     "AlarmSettings": [
                //         {
                //             "AlarmDesc": "ACC",
                //             "DeliverySpec": "OFF",
                //             "Comment": ""
                //         },
                //         {
                //             "AlarmDesc": "Harsh Cornering",
                //             "DeliverySpec": "2023-08-28T11:18:00.000Z",
                //             "Comment": ""
                //         }
                //     ],
                //     "Success": true,
                //     "Errors": [],
                //     "AvailableSettings": [
                //         "ACC",
                //         "Disconnect Main Power",
                //         "Low Voltage",
                //         "Over Speed",
                //         "Crash",
                //         "Harsh Braking",
                //         "Harsh Acceleration",
                //         "Harsh Cornering"
                //     ]
                // }

                if (data.Success === false && data.Errors && data.Errors.length > 0) {
                    setError(data.Errors[0])
                }
                else {
                    let settings = createSettingsFromServer(data.AlarmSettings, data.AvailableSettings)
                    setSettings(settings)
                    originalData.current = settings
                }
            })
            .catch(err => {
                setError(`${err}`)
                console.log("ERROR>> ", err)
            })

    }, [vehicle, setBusy, setSettings, setError, originalData])

    return (
        <Container fluid>
            {error === "" ? null : (
                <Row>
                    <Col>
                        <Alert color="danger">{error}</Alert>
                    </Col>
                </Row>
            )}
            <Row>
                <Col>
                    <span className="h5">Alarm Settings</span>
                </Col>
            </Row>
            <Row>
                <Col>
                    {busy ? <div><Spinner color="info" /></div> : settings.map((s, i) => (
                        <SettingEditor setting={s} key={i} onChange={(s) => updateSetting(i, s)} />
                    ))}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button color="primary" onClick={() => save(vehicle)}>Save</Button>
                    <Button className="mx-2" color="danger" onClick={reset}>Reset</Button>
                    <span>{saving !== "" ? <i>{saving}</i> : ""}</span>
                </Col>
            </Row>
        </Container>
    )
}

export default AlarmSettings