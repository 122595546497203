
import firebase from "firebase/app"
import "firebase/auth"
import { config } from "./firebaseConfig"
firebase.initializeApp(config);

export const doAuth = (success, failed) => {
    firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            success(user)
            console.log("LOGGED IN")
        } else {
            failed()
        }
    });
}