import { 
    VL_LOADING, 
    VL_SUCCESS, 
    VL_FAILED 
} from "../actions/actionTypes"

const initialState = {
    loading: false,
    vehicles: [],
    loadError: ""
}

export default function(state=initialState, action) {
    switch(action.type) {
        case VL_LOADING:
            return {
                loading: true,
                vehicles: [],
                loadError: ""
            }
        case VL_FAILED:
            return {
                loading: false,
                vehicles: [],
                loadError: action.errMessage
            }
        case VL_SUCCESS:
            return {
                loading: false,
                vehicles: action.vehicleList,
                loadError: ""
            }
        default:
            return state
    }
}