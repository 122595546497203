import React, { useRef, useState } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import { connect } from 'react-redux'
import { Container, Row, Col, FormGroup, Form, Label, Button } from 'reactstrap'
import AlarmSettings from './AlarmSettings'

const AlarmManager = ({vehicles}) => {

    const filterByFields = ['ClientDesc', 'VehicleDesc', 'Registration', 'TrackerID'];
    const [selectedVehicle, setSelectedVehicle] = useState(null)
    const typeaheadRef = useRef()

    const clear = () => {
        if (typeaheadRef.current != null) { 
            typeaheadRef.current.clear()
            setSelectedVehicle(null)
        }
    }

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }


    return (
        <Container className="mt-2">
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                            <Label for="vehicle-search" className="pl-3 h5">Vehicle Search</Label>
                            <div className="d-flex">
                                <Typeahead
                                    ref={typeaheadRef}
                                    className="flex-grow-1"
                                    filterBy={filterByFields}
                                    id="vehicle-search"
                                    labelKey={(v) => `${v.Registration} ${v.ClientDesc} ${v.VehicleDesc} [${v.TrackerID}]`}
                                    options={vehicles}
                                    placeholder="Search for vehicle or client.."
                                    renderMenuItemChildren={(option) => (
                                        <div>
                                            {option.ClientDesc}
                                            <div>
                                                <small>{option.Registration} {option.VehicleDesc} [{option.TrackerID}]</small>
                                            </div>
                                        </div>
                                    )}
                                    onChange={ (v) => setSelectedVehicle(v[0]) }
                                />
                                <Button className="ml-3" onClick={() => { clear() }}>Clear</Button>
                            </div>
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
            {/* <Row>
                <Col>
                    <Nav tabs>
                        <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => { toggle('1'); }}
                        >
                            Alarm History
                        </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => { toggle('2'); }}
                        >
                            Alarm Settings
                        </NavLink>
                        </NavItem>
                    </Nav>
                </Col>
            </Row> */}
            <Row>
                <Col>
                    { selectedVehicle? <AlarmSettings vehicle={selectedVehicle} /> : null }
                </Col>
            </Row>
        </Container>
    )

}

const mapStateToProps = (state) => {
    return {
        vehicles: state.vehicleList.vehicles
    }
}

export default connect(mapStateToProps)(AlarmManager)

