import React, { useState } from 'react'
import { Button, Container, FormGroup, Label, Form, Row, Col } from 'reactstrap'

import firebase from "firebase/app"
import { connect } from 'react-redux'
import { authSuccess } from "../../actions/actionTypes"
import { withRouter } from 'react-router'

import { useForm } from "react-hook-form"

const Login = ({ dispatch, history }) => {

    const { register, handleSubmit, formState: { errors } } = useForm()
    const [ error, setError ] = useState("")

    // "nishkarr@cybertracsa.co.za", "Sunjava1"

    const login = (data) => {
        setError("")

        let username = `${data.username}@cybertracsa.co.za`

        firebase.auth().signInWithEmailAndPassword(username, data.password).then((userCredential) => {
            setError("Log in success")
            dispatch(authSuccess(userCredential.user))
            history.push("/")
        })
        .catch((error) => {
            console.log(error)
            setError(error.message)
        })
    }

    return (
        <Container>
            <Row className="my-3">
                <Col>
                    <h3>Login</h3>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form onSubmit={handleSubmit(login)}>
                        <FormGroup>
                            <Label for="username">Username</Label>
                            <input className="form-control" {...register("username", { required: true, maxLength: 20 })} />
                            <span className="font-italic text-danger">{errors.username?.type === 'required' && "Username is required"}</span>
                        </FormGroup>
                        <FormGroup>
                            <Label for="password">Password</Label>
                            <input className="form-control" type="password" {...register("password", { required: true, maxLength: 20 })} />
                            <span className="font-italic text-danger">{errors.password?.type === 'required' && "Password is required"}</span>
                        </FormGroup>
                        
                        <Button color="primary" type="submit">Login</Button> <span className="ml-2 font-italic text-info">{error}</span>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default connect()(withRouter(Login))