import { 
    AUTH_LOADING, 
    AUTH_SUCCESS, 
    AUTH_FAILED 
} from '../actions/actionTypes'

const initialState = {
    authLoading: true,
    authenticated: false,
    user: {}
}

export default function(state=initialState, action) {
    switch(action.type) {
        case AUTH_SUCCESS:
            return {
                authLoading: false,
                authenticated: true,
                user: action.user
            }        
        case AUTH_LOADING:
            return {
                authLoading: true,
                authenticated: false,
                user: null
            }     
        case AUTH_FAILED:
            return {
                authLoading: false,
                authenticated: false,
                user: null
            }  
        default:
            return initialState
    }
}