export const AUTH_LOADING = "AUTH_LOADING"
export const AUTH_SUCCESS = "AUTH_SUCCESS"
export const AUTH_FAILED = "AUTH_FAILED"

export const VL_LOADING = "VL_LOADING"
export const VL_SUCCESS = "VL_SUCCESS"
export const VL_FAILED = "VL_FAILED"

const authLoading = () => {
    return { type: AUTH_LOADING }
}

const authSuccess = (user) => {
    return { type: AUTH_SUCCESS, user }
}

const authFailed = () => {
    return { type: AUTH_FAILED }
}

const vehicleListLoading = () => {
    return { type: VL_LOADING }
}

const vehicleListSuccess = (vehicleList) => {
    return { type: VL_SUCCESS, vehicleList }
}

const vehicleListFailed = (errMessage) => {
    return { type: VL_FAILED, errMessage }
}

export { authLoading, authSuccess, authFailed, vehicleListLoading, vehicleListSuccess, vehicleListFailed }