import React, { useState } from 'react'
import { Container, Row, Col, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, Label } from 'reactstrap'

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "./SettingEditor.scss"

import Setting from './Setting'

function colorFromStatus(status) {
    switch(status){
        case "ON":
            return "success"
        case "OFF":
            return "danger"
        case "SNOOZED":
            return "warning"
        case "ON-UNTIL":
            return "info"
        default:
            return ""
    }
}

const friendlyName = (name) => {
    switch(name) {
        case "ACC":
            return "Ignition On"
        case "Disconnect Main Power":
            return "Battery Disconnect"
        default:
            return name
    }
}

const timedLabelText = (status) => {
    if (status === "SNOOZED") {
        return "Alarms OFF until"
    }
    if (status === "ON-UNTIL") {
        return "Alarms ON until"
    }
}

const SettingEditor = ({setting, onChange}) => {

    const [dropdownOpen, setDropdownOpen] = useState(false)

    const toggle = () => {
        setDropdownOpen(!dropdownOpen)
    }

    const statusChange = (status) => {
        const newSetting = Setting(setting.name, status, setting.date, setting.comment)
        onChange(newSetting)
    }

    const dateChange = (date) => {
        console.log(date.toISOString())
        const newSetting = Setting(setting.name, setting.status, date.toISOString(), setting.comment)
        onChange(newSetting)
    }

    const commentChange = (comment) => {
        const newSetting = Setting(setting.name, setting.status, setting.date, comment)
        onChange(newSetting)
    }

    let dropdownColor = colorFromStatus(setting.status)

    const timed = setting.status === "SNOOZED" || setting.status === "ON-UNTIL"

    const date = Date.parse(setting.date)

    return (
        <Container fluid>
            <Row className="my-2">
                <Col>
                    <span className="font-weight-bold">{ friendlyName(setting.name) }</span>
                </Col>
                <Col>
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle color={dropdownColor} caret>
                            { setting.status }
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem onClick={() => statusChange("ON")}>ON</DropdownItem>
                            <DropdownItem onClick={() => statusChange("OFF")}>OFF</DropdownItem>
                            <DropdownItem divider/>
                            <DropdownItem onClick={() => statusChange("SNOOZED")}>SNOOZED</DropdownItem>
                            <DropdownItem onClick={() => statusChange("ON-UNTIL")}>ON-UNTIL</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </Col>
            </Row>
            { timed && (
                <Row className="bg-light pb-2">
                    <Col className="d-flex flex-column">
                        <Label className="font-italic">{timedLabelText(setting.status)}</Label>
                        <DatePicker
                            selected={date} onChange={dateChange} 
                            showTimeSelect
                            timeFormat="HH:mm"
                            dateFormat="MMMM d, yyyy h:mm aa"
                        />
                    </Col>
                    <Col className="d-flex flex-column">
                        <Label className="font-italic">Comment</Label>
                        <input type="textarea" value={setting.comment} onChange={e => { commentChange(e.target.value)}} />
                    </Col>
                </Row>
            )}
        </Container>
    )
}

export default SettingEditor